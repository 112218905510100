import React from 'react';
import volumebotstyle from '../style/moonshot.module.less';

export const MoonBotInfo = () => (
  <div className={volumebotstyle.infoContainer}>
    <section className={volumebotstyle.section}>
      <h2>Moonshot Bot</h2>
      <p>
        The Moonshot Bot is designed for generating volume, transactions for
        tokens live on Moonshot.
      </p>
      <p>
        Here's how to generate volume, transactions using our Moonshot bot::-
      </p>
      <ul>
        <li>
          <b>Load the Token:</b> Begin by loading the token using its mint
          address.
        </li>
        <li>
          <b>Confirm LP:</b> Check that your LP token address is correct.
        </li>
        <li>
          <b>Set Total Transactions:</b> Decide on the total number of
          transactions you want the bot to perform.
        </li>
        <li>
          <b>Adjust the Rate:</b> Set the rate, which is the number of
          transactions per mint the bot will execute on the token. Each
          transaction consists of one buy and one sell.
        </li>
        <li>
          <b>Choose Transaction Amount:</b> Select the amount of SOL the bot
          will use for each buy and sell transaction.
        </li>
      </ul>
      <br />
      <p>
        For more information or if you have any questions, please contact us on
        Telegram or Twitter.
      </p>
    </section>
  </div>
);
