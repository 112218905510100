import { useQuery } from '@tanstack/react-query';
import { httpClientBareMetal } from '../../utils/api';

export const useGetPopulartokens = ({ mint_type = 1 }) => {
  const url = '/api/v1/snapshot/asset_list';
  const queryParams = new URLSearchParams({ mint_type }).toString(); // Convert params to query string
  const fullUrl = `${url}?${queryParams}`; // Append query string to URL

  return useQuery({
    queryKey: [fullUrl],
    queryFn: () => httpClientBareMetal({ url: fullUrl }),
    select: res => res?.data?.data || [],
  });
};
