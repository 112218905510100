import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from 'antd/es/card';
import { useWallet } from '@solana/wallet-adapter-react';
import useDevice from '../../../hooks/useDevice';

export const TokenInfo = ({
  tokenDetails,
  loading = false,
  tokenHoldersCount,
  isHolderLoading,
  feeAmount,
  ...props
}) => {
  return (
    <Card size='small' title={`Token Details`} loading={loading} {...props}>
      <div className='flex items-start gap-x-8 overflow-x-auto md:flex-col md:w-[100%]'>
        <img
          className='mt-1 md:h-[100px] md:w-[100px]'
          width={70}
          height={70}
          src={tokenDetails?.fileData?.image}
          alt=''
        />
        <div className='flex gap-x-4 flex-1 md:flex-col md:w-[100%]'>
          <div className='flex flex-col gap-y-2 w-[50%] md:w-[100%]'>
            <p className='m-0'>
              <span>Token Name:&nbsp;&nbsp;</span>
              <span className='font-bold'>
                {tokenDetails?.metadata?.data?.name || '-'}
              </span>
            </p>
            <p className='m-0'>
              <span>Token Ticker:&nbsp;&nbsp;</span>
              <span className='font-bold'>
                {tokenDetails?.fileData?.symbol || '-'}
              </span>
            </p>
            <p className='m-0'>
              <span>Token Description:&nbsp;&nbsp;</span>
              <span className='font-bold'>
                {tokenDetails?.fileData?.description || '-'}
              </span>
            </p>
          </div>
          <div className='flex flex-col gap-y-2 w-[50%] md:w-[100%]'>
            <p className='m-0'>
              <span>Token holders:&nbsp;&nbsp;</span>
              <span className='font-bold'>
                {isHolderLoading ? 'Calculating...' : tokenHoldersCount}
              </span>
            </p>
            <p className='m-0'>
              <span>Platform Fee:&nbsp;&nbsp;</span>
              <span className='font-bold'>
                {isHolderLoading ? 'Calculating...' : `${feeAmount} SOL`}
              </span>
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};
