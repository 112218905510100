import React from 'react';
import pageStyle from '../style/page.module.less';

export const PageTitle = ({ mainText, subText }) => {
  return (
    <div className={pageStyle.container}>
      <h1 className={pageStyle.title}>
        <span className={pageStyle.purpleText}>{mainText}: </span>
        {subText}
      </h1>
      <div className={pageStyle.headerLine} />
    </div>
  );
};
