import React, { useLayoutEffect, useRef } from 'react';
import Tooltip from 'antd/es/tooltip';

import snapStyle from '../style/snapshot.module.less';
import { useGetPopulartokens } from '../api';

const RenderToken = ({ el }) => {
  const [tooltipText, setTooltipText] = React.useState(el?.name);
  const handleTooltipVisibleChange = visible => {
    if (!visible) {
      setTooltipText(el?.name);
    }
  };

  return (
    <Tooltip
      key={el?.mint_address}
      title={tooltipText}
      onOpenChange={handleTooltipVisibleChange}>
      <div
        className='cursor-pointer flex flex-col items-center gap-y-1'
        onClick={() => {
          navigator.clipboard.writeText(el?.mint_address);
          setTooltipText('Mint address copied !!');
        }}>
        <img
          src={el?.logo_uri}
          alt=''
          height='90'
          width='90'
          className='rounded-md'
        />
        <p>{el?.symbol || '-'}</p>
      </div>
    </Tooltip>
  );
};

const AssetList = ({ mint_type }) => {
  const { data: tokenList } = useGetPopulartokens({ mint_type });
  return (
    <div className='flex gap-2 flex-wrap justify-center'>
      {tokenList?.map?.(el => (
        <RenderToken key={el?.mint_address} el={el} />
      ))}
    </div>
  );
};

export const SnapInfo = ({ type }) => {
  const ref = useRef(null);
  useLayoutEffect(() => {
    const syncHeights = () => {
      const leftHeight = document.getElementById('left-container').clientHeight;
      if (leftHeight) {
        ref.current.style.maxHeight = `calc(${leftHeight}px - 48px)`;
      }
    };

    syncHeights();

    window.addEventListener('resize', syncHeights);

    return () => {
      window.removeEventListener('resize', syncHeights);
    };
  }, []);

  return (
    <div ref={ref} className={snapStyle.infoContainer}>
      <div className={snapStyle.stickyContainer}>
        <h3 className={snapStyle.stickyHeader}>
          Popular {type === 2 ? 'NFTs' : 'Tokens'}
        </h3>
      </div>
      <AssetList mint_type={type} />
    </div>
  );
};
