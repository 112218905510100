import React, { useMemo, useState } from 'react';
import Input from 'antd/es/input';
import { HyperButton } from '../../../components/buttons/HyperButton';
import snapStyle from '../style/snapshot.module.less';
import useDevice from '../../../hooks/useDevice';
import { SNAPSHOT_TYPE } from '../../../constants';

const LoadToken = ({
  loadToken,
  loadingTokenDetails,
  type = SNAPSHOT_TYPE.TOKEN_SN,
}) => {
  const [token, setToken] = useState('');
  const { isMobile } = useDevice();

  const addonBefore = useMemo(() => {
    return type === SNAPSHOT_TYPE.TOKEN_SN
      ? 'Mint Address'
      : 'Collection Address';
  }, [type]);

  return (
    <>
      <h3>
        {type === SNAPSHOT_TYPE.TOKEN_SN ? 'Token' : 'Collection'} address
      </h3>
      <div className='flex items-center gap-x-6 w-full md:flex-col md:gap-y-[1rem]'>
        <Input
          className={`${snapStyle.mintAddress} text-[2rem] h-[42px] md:h-[2.5rem] md:text-[1.25rem]`}
          placeholder={
            type === SNAPSHOT_TYPE.TOKEN_SN
              ? 'Token Mint address'
              : 'NFT Collection address'
          }
          addonBefore={!isMobile ? addonBefore : null}
          value={token}
          onChange={e => {
            setToken(e.target.value);
          }}
        />

        <HyperButton
          btnSize='medium-btn'
          text={loadingTokenDetails ? 'Loading' : 'Load'}
          onClick={() => loadToken(token)}
          disabled={loadingTokenDetails || !token.trim()}
          loading={loadingTokenDetails}
          className='md:w-[100%]'
        />
      </div>
    </>
  );
};

export default LoadToken;
