import React from 'react';
import { Link } from 'react-router-dom';

export const tokenFaqItems = [
  {
    key: '1',
    label: 'What is the Solana Token Snapshot Tool?',
    children: (
      <>
        <p>
          The Solana Token Snapshot Tool is a utility that lets users generate a
          snapshot of token holders for a specific SPL or SPL22 token. The
          snapshot includes details such as the top X token-holding addresses or
          all addresses meeting a specified minimum token balance.
        </p>
      </>
    ),
  },
  {
    key: '2',
    label: 'Can I use this tool for both SPL and SPL22 tokens?',
    children: (
      <>
        <p>
          Yes, this tool supports snapshots for both SPL and SPL22 tokens on the
          Solana blockchain, including tokens generated via platforms like
          PumpFun and MoonShot.
        </p>
      </>
    ),
  },
  {
    key: '3',
    label: 'Is there a limit to the number of addresses I can include?',
    children: (
      <>
        <p>
          The tool supports custom input for the number of addresses, with
          popular choices like 500, 1000, 2000, and 5000. A token with large
          number of holders might require some minutes to generate its snapshot.
        </p>
      </>
    ),
  },
  {
    key: '4',
    label: 'How can I access the generated snapshot file?',
    children: (
      <>
        <p>
          You can access your generated snapshots in Snapshot History page. You
          can download the snapshot file in csv format by clicking on download
          button. Snapshot file will containes holder address along with number
          of token held by that particular address.
        </p>
      </>
    ),
  },
  {
    key: '5',
    label: 'What is the fee for generating a snapshot?',
    children: (
      <>
        <p>
          A: The fee for generating a snapshot varies based on the number of
          holders in the snapshot:
        </p>
        <ul>
          <li>
            For less than 2,500 holders: The fee is <strong>0.1 SOL</strong>.
          </li>
          <li>
            For 2,500 to 249,999 holders: The fee increases to{' '}
            <strong>0.25 SOL</strong>.
          </li>
          <li>
            For 250,000 to 999,999 holders: The fee is <strong>0.5 SOL</strong>.
          </li>
          <li>
            For 1,000,000 or more holders: The fee is <strong>1 SOL</strong>.
          </li>
        </ul>
        <p>
          The fee is automatically calculated and displayed in the tool before
          confirming payment.
        </p>
      </>
    ),
  },
  {
    key: '6',
    label: 'Whom should I contact for support or issues?',
    children: (
      <>
        <p>
          For assistance, reach out to the Hypersol team via{' '}
          <Link target='_blank' to='https://t.me/hypersol'>
            Telegram
          </Link>{' '}
          or{' '}
          <Link target='_blank' to='https://x.com/hypersolX'>
            Twitter
          </Link>
          .
        </p>
      </>
    ),
  },
];

export const nftFaqItems = [
  {
    key: '1',
    label: 'What is NFT Snapshot tool?',
    children: (
      <>
        <p>
          The NFT snapshot tool is designed to generate snapshot of NFT Holders
          and number of NFTs held by each holder on Solana at a specific time.
          It helps projects track holder base, conduct airdrops etc.
        </p>
      </>
    ),
  },
  {
    key: '2',
    label: 'What information does the snapshot file provide?',
    children: (
      <>
        <p>The snapshot includes wallet addresses holding the NFT.</p>
      </>
    ),
  },
  {
    key: '3',
    label: 'Is there a limit to the number of addresses I can include?',
    children: (
      <>
        <p>
          The tool supports custom input for the number of addresses, with
          popular choices like 500, 1000, 2000, and 5000. A token with large
          number of holders might require some minutes to generate its snapshot.
        </p>
      </>
    ),
  },
  {
    key: '4',
    label: 'How can I access the generated snapshot file?',
    children: (
      <>
        <p>
          You can access your generated snapshots in Snapshot History page. You
          can download the snapshot file in csv format by clicking on download
          button. Snapshot file will containes holder address along with number
          of token held by that particular address.
        </p>
      </>
    ),
  },
  {
    key: '5',
    label: 'What is the fee for generating a snapshot?',
    children: (
      <>
        <p>
          A: The fee for generating a snapshot varies based on the number of
          holders in the snapshot:
        </p>
        <ul>
          <li>
            For less than 2,500 holders: The fee is <strong>0.1 SOL</strong>.
          </li>
          <li>
            For 2,500 to 249,999 holders: The fee increases to{' '}
            <strong>0.25 SOL</strong>.
          </li>
          <li>
            For 250,000 to 999,999 holders: The fee is <strong>0.5 SOL</strong>.
          </li>
          <li>
            For 1,000,000 or more holders: The fee is <strong>1 SOL</strong>.
          </li>
        </ul>
        <p>
          The fee is automatically calculated and displayed in the tool before
          confirming payment.
        </p>
      </>
    ),
  },
  {
    key: '6',
    label: 'Whom should I contact for support or issues?',
    children: (
      <>
        <p>
          For assistance, reach out to the Hypersol team via{' '}
          <Link target='_blank' to='https://t.me/hypersol'>
            Telegram
          </Link>{' '}
          or{' '}
          <Link target='_blank' to='https://x.com/hypersolX'>
            Twitter
          </Link>
          .
        </p>
      </>
    ),
  },
];
