import React from 'react';
import { SnapshotHistoryIcon } from '../../components/icons/snapshotHistory';
import { Snapshot2Icon } from '../../components/icons/snapshot2';

export const SUB_APP_NAVIGATION = [
  {
    name: 'Token Snapshot',
    route: 'token-snapshot',
    key: 'token-snapshot',
    icon: <Snapshot2Icon />,
  },
  {
    name: 'NFT Snapshot',
    route: 'nft-snapshot',
    key: 'nft-snapshot',
    icon: <Snapshot2Icon />,
  },
  {
    name: 'Manage Snapshots',
    route: 'snap-history',
    key: 'snap-history',
    icon: <SnapshotHistoryIcon />,
  },
];
