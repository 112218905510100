/* eslint-disable no-unused-expressions */
import React, { useContext, useState } from 'react';

import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {
  ComputeBudgetProgram,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
} from '@solana/web3.js';
import { useNavigate } from 'react-router-dom';
import { SnapShotForm } from '../components/SnapShotForm';
import { MULTI_SENDER_FEE_COLLECTOR } from '../../../envs/vars';
import { SolUtils } from '../../../solana/SolUtils';
import { NotifyContext } from '../../../context/Notify';
import { httpClientBareMetal } from '../../../utils/api';
import { PageTitle } from '../../../components/PageTitle';
import { getMode, loadTokenDetailsBE } from '../../../utils/helpers';
import { FaqSection } from '../../../components/faq/Faq';
import { faqItems, nftFaqItems, tokenFaqItems } from '../../../utils/faqdata';
import { SnapInfo } from '../components/SnapInfo';
import useDevice from '../../../hooks/useDevice';
import { SNAPSHOT_TYPE } from '../../../constants';

export const TokenSnapShot = ({ type }) => {
  const [notifyApi] = useContext(NotifyContext);
  const [isHolderLoading, setIsHolderLoading] = useState(false);

  const { connection } = useConnection();
  const wallet = useWallet();
  const [platformFee, setPlatformFee] = useState({});

  const [tokenAmount, setTokenAmount] = useState({
    amountToken: null,
    tokenvalue: 'ALL',
  });
  const [isTokenLoading, setIsTokenLoading] = useState(false);

  const [isFormInSubmission, setIsFormInSubmission] = useState(false);

  const [tokenDetails, setTokenDetails] = useState(null);
  const [inputvalue, setInputvalue] = useState(null);
  const { isMobile } = useDevice();

  const navigate = useNavigate();

  const handleRadioChange = e => {
    const { value } = e.target;

    setTokenAmount(prev => ({
      ...prev,
      tokenvalue: prev.tokenvalue == value ? null : value,
    }));
  };

  const handleCustomInputChange = e => {
    const { value } = e.target;
    setInputvalue(value);
  };

  const getPlatformFee = (mint_address, type = 1) => {
    return new Promise((resolve, reject) => {
      httpClientBareMetal
        .request({
          method: 'GET',
          url: 'api/v1/snapshot/platformFee',
          params: {
            mintAddress: mint_address,
            mintType: type,
          },
        })
        .then(response => {
          resolve(response?.data?.data?.[0]);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  const loadToken = async mintAddress => {
    // load token infomation from mint address
    try {
      setIsTokenLoading(true);
      setTokenDetails(null);

      let [tkDetails] = await loadTokenDetailsBE({
        address: mintAddress,
        mode: getMode(connection),
        endpoint:
          type === SNAPSHOT_TYPE.TOKEN_SN ? 'token-details' : 'nft-details',
      });

      if (type === SNAPSHOT_TYPE.NFT_SN) {
        tkDetails = {
          metadata: {
            data: {
              name: tkDetails?.name,
            },
          },
          fileData: {
            symbol: tkDetails?.symbol || '',
            image: tkDetails?.json?.image || '',
            description: tkDetails?.json?.description || '',
          },
          creators: tkDetails?.creators || [],
          mint: tkDetails?.mint?.address,
        };
      }

      setTokenDetails(tkDetails);
      setIsTokenLoading(false);

      setIsHolderLoading(true);
      const pf = await getPlatformFee(mintAddress, type);
      setPlatformFee(pf);
    } catch (err) {
      console.error('Error loading token:', err);
    } finally {
      setIsHolderLoading(false);
      setIsTokenLoading(false);
    }
  };

  const buildInstructions = platformFee => {
    const instructions = [
      ComputeBudgetProgram.setComputeUnitLimit({ units: 100000 }),
      ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 2 * 1000000 }),
      SystemProgram.transfer({
        fromPubkey: wallet.publicKey,
        toPubkey: new PublicKey(MULTI_SENDER_FEE_COLLECTOR),
        lamports: platformFee,
      }),
    ];

    return instructions;
  };

  const submitSnapshotRequest = ({
    signed_transaction,
    mintAddress,
    type = 1,
    tokenAmount,
  }) =>
    new Promise((resolve, reject) => {
      httpClientBareMetal
        .request({
          method: 'POST',
          url: `api/v1/snapshot/${wallet.publicKey.toBase58()}/${type === SNAPSHOT_TYPE.TOKEN_SN ? 'snapshot' : 'snapshot_nft'}`,

          data: {
            transaction: signed_transaction,
            mintAddress,
            minAmount: tokenAmount?.amountToken || '0',
            maxAmount: '10000000000',
            symbol: tokenDetails?.fileData?.symbol || '-',
            creator: tokenDetails?.creators?.[0]?.address || '',
            topAccounts:
              (tokenAmount?.tokenvalue === 'custom'
                ? inputvalue
                : tokenAmount?.tokenvalue) || 'ALL',
          },
        })
        .then(response => {
          resolve(response?.data);
        })
        .catch(err => {
          reject(err);
        });
    });

  const takeSnaphot = async type => {
    // perform snapshot
    const notifyId = 'snap_id';
    try {
      setIsFormInSubmission(true);
      const platformFee = await getPlatformFee(tokenDetails.mint, type);
      const instructions = buildInstructions(platformFee?.feeDetails?.amount);

      const transaction = await SolUtils.buildTransaction(
        connection,
        instructions,
        wallet.publicKey,
        0,
      );

      const signed_transaction = await SolUtils.getSignedTransaction(
        connection,
        transaction,
        wallet,
        wallet.publicKey,
        [],
        0,
      );

      //   const tx_mode =
      //     appStore?.currentNetwork === NETWORKS.MAINNET
      //       ? TRANSACTION_MODE.MAINNET
      //       : TRANSACTION_MODE.DEVNET;

      notifyApi &&
        notifyApi.open({
          key: notifyId,
          placement: 'bottomRight',
          message: 'Processing Request',
          description:
            'We are processing your request, details will be available in Snapshot history tab shortly.',
          duration: 15,
        });

      const base64Transaction = Buffer.from(
        signed_transaction.serialize(),
      ).toString('base64');

      await submitSnapshotRequest({
        signed_transaction: base64Transaction,
        mintAddress: tokenDetails.mint,
        type,
        tokenAmount,
      });

      notifyApi &&
        notifyApi.open({
          key: notifyId,
          placement: 'bottomRight',
          message: 'Request Completed',
          description:
            'Your request is completed. Please check snapshot history tab to download holders snapshot. Or Refresh the Snapshot history page !!',
          duration: 5,
        });

      return navigate('/snapshot/snap-history');
    } catch (err) {
      console.log('error occured in snapshot..', err);
      notifyApi &&
        notifyApi.open({
          key: notifyId,
          placement: 'bottomRight',
          message: 'Request Failed',
          description:
            'Your request is failed, try again by refreshing the page. Please contact team if issue persists',
          duration: 5,
        });
    } finally {
      setIsFormInSubmission(false);
    }
  };

  //   console.log('platform fee', platformFee);

  const feeAmount = (
    (platformFee?.feeDetails?.amount || '0') / LAMPORTS_PER_SOL
  ).toFixed(3);

  //   console.log('token details', tokenDetails, tokenAmount);

  return (
    <div className='flex gap-x-[2rem] flex-row md:flex-col md:gap-y-[2rem] items-start'>
      <div
        id='left-container'
        className='flex gap-y-[2rem] flex-col w-[70%] md:w-[100%]'>
        <div className='flex flex-col p-6 gap-y-4 border bg-[var(--main-background-color)] border-[var(--main-border-color)] rounded-[5px]'>
          <PageTitle
            mainText='Snapshot'
            subText={`Take snapshot of ${type === SNAPSHOT_TYPE.TOKEN_SN ? 'token' : 'NFT'} holders`}
          />

          <SnapShotForm
            type={type}
            loadToken={loadToken}
            tokenDetails={tokenDetails}
            takeSnaphot={takeSnaphot}
            isTokenLoading={isTokenLoading}
            tokenAmount={tokenAmount}
            handleRadioChange={handleRadioChange}
            setTokenAmount={setTokenAmount}
            isFormInSubmission={isFormInSubmission}
            tokenHoldersCount={platformFee?.feeDetails?.holders}
            isHolderLoading={isHolderLoading}
            handleCustomInputChange={handleCustomInputChange}
            feeAmount={feeAmount}
          />
        </div>
        <div className='flex flex-col p-6 gap-y-4 border bg-[var(--main-background-color)] border-[var(--main-border-color)] rounded-[5px]'>
          {isMobile ? (
            <SnapInfo type={type} />
          ) : (
            <FaqSection
              faqItems={
                type === SNAPSHOT_TYPE.TOKEN_SN ? tokenFaqItems : nftFaqItems
              }
            />
          )}
        </div>
      </div>
      <div className='w-[30%] md:w-[100%] self-stretch p-6 gap-y-4 border bg-[var(--main-background-color)] border-[var(--main-border-color)] rounded-[5px]'>
        {isMobile ? (
          <FaqSection
            faqItems={
              type === SNAPSHOT_TYPE.TOKEN_SN ? tokenFaqItems : nftFaqItems
            }
          />
        ) : (
          <SnapInfo type={type} />
        )}
      </div>
    </div>
  );
};
