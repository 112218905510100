import React, { useMemo } from 'react';
import Input from 'antd/es/input';
import Tooltip from 'antd/es/tooltip';
import Radio from 'antd/es/radio';
import { InfoCircleOutlined } from '@ant-design/icons';

import snapshotStyle from '../style/snapshot.module.less';
import { HyperButton } from '../../../components/buttons/HyperButton';
import { snapFilterOptions, SNAPSHOT_TYPE } from '../../../constants';
import LoadToken from './LoadToken';
import { TokenInfo } from './TokenInfo';
import useDevice from '../../../hooks/useDevice';

export const SnapShotForm = ({
  type,
  loadToken,
  tokenDetails,
  takeSnaphot,
  isTokenLoading,
  tokenAmount,
  setTokenAmount,
  handleRadioChange,
  isFormInSubmission,
  tokenHoldersCount,
  isHolderLoading,
  inputvalue,
  handleCustomInputChange,
  feeAmount,
}) => {
  const options = useMemo(() => snapFilterOptions(), []);
  const IscustomInput = useMemo(() => {
    return tokenAmount?.tokenvalue === 'custom';
  }, [tokenAmount?.tokenvalue]);

  const { isMobile } = useDevice();
  const disabled = useMemo(
    () =>
      type === SNAPSHOT_TYPE.TOKEN_SN
        ? !tokenDetails?.decimals || isFormInSubmission
        : !tokenDetails?.fileData?.symbol ||
          !tokenHoldersCount ||
          isFormInSubmission,
    [
      tokenDetails?.decimals,
      tokenDetails?.fileData?.symbol,
      tokenHoldersCount,
      isFormInSubmission,
      type,
    ],
  );

  return (
    <div className='flex flex-col gap-y-8'>
      <LoadToken
        loadToken={loadToken}
        loadingTokenDetails={isTokenLoading}
        type={type}
      />
      {tokenDetails ? (
        <TokenInfo
          tokenDetails={tokenDetails}
          loading={isTokenLoading}
          tokenHoldersCount={tokenHoldersCount}
          isHolderLoading={isHolderLoading}
          feeAmount={feeAmount}
        />
      ) : null}

      <div className={`${snapshotStyle.cardContent} flex flex-col gap-y-6  `}>
        {type === SNAPSHOT_TYPE.TOKEN_SN && (
          <>
            <div>
              <p>
                Top Holders Filter &nbsp;
                <Tooltip title='Specify the number of top holders you wish to capture or select from the available options. To capture all address keep the input empty !!'>
                  <InfoCircleOutlined />
                </Tooltip>
              </p>

              <div className='flex gap-6 flex-col '>
                <Radio.Group
                  optionType='button'
                  onChange={handleRadioChange}
                  buttonStyle='solid'
                  value={tokenAmount.tokenvalue}>
                  {options.map(option => (
                    <Radio.Button
                      className='md:my-[0.5rem] md:mx-[0.5rem] md:before:!hidden md:border-l-[1px] md:rounded-2xl'
                      key={option.value}
                      value={option.value}>
                      {option.label}
                    </Radio.Button>
                  ))}
                </Radio.Group>
                {IscustomInput && (
                  <Input
                    type='number'
                    size={isMobile ? 'medium' : 'large'}
                    className={`width-[120px] md:width-[100%] border ${
                      !options.some(
                        item => item.value == tokenAmount?.tokenvalue,
                      )
                        ? 'border-purple-700'
                        : ''
                    }`}
                    value={inputvalue}
                    onChange={handleCustomInputChange}
                    placeholder='Top X holders'
                    style={{
                      backgroundColor: 'white',
                    }}
                  />
                )}
              </div>
            </div>

            <div className={snapshotStyle.formField}>
              <p>
                Minimum Token Holdings &nbsp;
                <Tooltip title='Minimum amount of tokens that holder must hold to be part of snapshot. For snapshot of all holders, add 0 or leave it empty'>
                  <InfoCircleOutlined />
                </Tooltip>
              </p>
              <Input
                size={isMobile ? 'medium' : 'large'}
                allowClear
                type='number'
                placeholder='Enter token amount'
                value={tokenAmount.amountToken}
                onChange={event =>
                  setTokenAmount(prev => ({
                    ...prev,
                    amountToken: event.target.value,
                  }))
                }
              />
            </div>
          </>
        )}
        <HyperButton
          disabled={disabled}
          className='w-[300px] md:w-[100%]'
          style={{ marginInline: 'auto' }}
          text={`Take Snapshot (${feeAmount})`}
          onClick={() => takeSnaphot(type)}
        />
      </div>
    </div>
  );
};
