import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { TokenSnapShot } from './container/TokenSnapShot';
import HelmetLayout from '../../components/HelmetLayout';

import { SnapShotTokenHistory } from './container/SnapShotTokenHistory';
import { SNAPSHOT_TYPE } from '../../constants';

export const SnapShot = () => {
  return (
    <>
      <HelmetLayout
        title='Token Holder Snapshot: Instantly Capture SPL, SPL22, NFT Holders'
        description='Quickly capture holders of SPL, SPL22 tokens and NFT with our Snapshot Tool. Easily filter holders based on token thresholds. Simplify your token analysis and governance processes effortlessly.'
        keywords='Token holder snapshot, SPL token snapshot, SPL22 token snapshot, token holder analysis, token governance, Solana blockchain tools, token management utilities, NFT Snapshot'
      />
      <Routes>
        <Route path='/' element={<TokenSnapShot />} />
        <Route
          path='token-snapshot'
          element={
            <TokenSnapShot
              type={SNAPSHOT_TYPE.TOKEN_SN}
              key={'tokenSnapshot'}
            />
          }
        />
        <Route
          path='nft-snapshot'
          element={
            <TokenSnapShot type={SNAPSHOT_TYPE.NFT_SN} key={'nftSnapshot'} />
          }
        />
        <Route path='snap-history' element={<SnapShotTokenHistory />} />
      </Routes>
    </>
  );
};
