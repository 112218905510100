/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import Footer from '../components/Footer';

const WithFooter = WrappedComponent =>
  forwardRef((props, ref) => (
    <div
      className='flex flex-col justify-between overflow-auto w-full'
      ref={ref}
    >
      <WrappedComponent {...props} />
      <Footer />
    </div>
  ));

export default WithFooter;
