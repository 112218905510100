import React, { useState } from 'react';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import { useConnection } from '@solana/wallet-adapter-react';
import { HyperButton } from '../../../components/buttons/HyperButton';
import { loadTokenDetailsBE } from '../../../utils/helpers';
import { getBondingCurveStatus, getSolPrice } from '../utils/apiCalls';
import bumpiStyle from '../style/bumpi.module.less';
import { getMode } from '../utils/helpers';
import useDevice from '../../../hooks/useDevice';

const LoadToken = ({ setTokenDetails, setSolPrice = () => {} }) => {
  const [token, setToken] = useState('');
  const [loadingTokenDetails, setLoadingTokenDetails] = useState(false);
  const { connection } = useConnection();
  const { isMobile } = useDevice();

  async function loadToken(address) {
    try {
      setLoadingTokenDetails(true);
      setTokenDetails(null);
      const [[result = null], bondingStatus, solPrice] = await Promise.all([
        loadTokenDetailsBE({
          address,
          mode: getMode(connection),
        }),
        getBondingCurveStatus({ mintAddress: address }),
        getSolPrice(),
      ]);

      if (result?.decimals) {
        setTokenDetails({ ...result, ...bondingStatus });
        console.log('solPrice', solPrice);
        setSolPrice(solPrice);
        return;
      }
      throw new Error('Invalid token');
    } catch (err) {
      setTokenDetails(null);
      return message.error(err?.message || 'Invalid Token');
    } finally {
      setLoadingTokenDetails(false);
    }
  }

  return (
    <>
      <h3>Token address</h3>
      <div className='flex items-center gap-x-6 w-full md:flex-col md:gap-y-[1rem]'>
        <Input
          className={`h-[42px] ${isMobile ? 'h-[32px] font-[1rem]' : ''} ${bumpiStyle.mintAddress}`}
          placeholder='Token Mint address'
          addonBefore={!isMobile ? 'Mint Address' : null}
          value={token}
          onChange={e => {
            setToken(e.target.value);
          }}
        />

        <HyperButton
          btnSize='medium-btn'
          text={loadingTokenDetails ? 'Loading' : 'Load'}
          onClick={() => loadToken(token)}
          disabled={loadingTokenDetails || !token.trim()}
          loading={loadingTokenDetails}
          className='md:w-[100%]'
        />
      </div>
    </>
  );
};

export default LoadToken;
