import React from 'react';
import volumebotstyle from '../style/volumebot.module.less';

export const VolumeBotInfo = ({ title = 'Bump' }) => (
  <div className={volumebotstyle.infoContainer}>
    <section className={volumebotstyle.section}>
      <h2>Here's how to generate {title} using our Raydium bot::-</h2>
      <ul>
        <li>
          <b>Load the Token:</b> Begin by loading the token using its mint
          address.
        </li>
        <li>
          <b>Select & Verify Pool:</b> Check that your pool address is correct
          by verifying on dexscreener
        </li>
        <li>
          <b>Set Total Transactions:</b> Decide on the total number of
          transactions you want the bot to perform.
        </li>
        <li>
          <b>Adjust the Rate:</b> Set the rate, which is the number of
          transactions per mint the bot will execute on the token. Each
          transaction consists of one buy and one sell.
        </li>
        <li>
          <b>Choose Transaction Amount:</b> Select the amount of SOL the bot
          will use for each buy and sell transaction.
        </li>
        <li>
          <b>Generate Wallet</b> Click on Generate Wallet button and save the
          private keys of bot wallet.
        </li>
        <li>
          <b>Pay & Start</b> Click on Pay & Start button and confirm the
          transaction in your wallet.
        </li>
        <li>
          <b>Manage Bot</b> Head over to the manage section to check the bot
          stats.
        </li>
      </ul>
      <br />
      <p>
        For more information or if you have any questions, please contact us on
        Telegram or Twitter.
      </p>
    </section>
  </div>
);
