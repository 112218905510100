import React, { useMemo, useRef, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import message from 'antd/es/message';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import { TokenInfo } from '../components/TokenInfo';
import LoadToken from '../components/LoadToken';
import MoonBotForm from '../components/MoonBotForm';
import { HyperButton } from '../../../components/buttons/HyperButton';
import {
  BASE_PDA,
  BONDING_CHARGE,
  BOT_BASE_FEE,
  BOT_GAS_FEE,
  FACTOR,
  HYPERPAD_CHARGE,
  JITO_TIP_1X,
  PUMPFUN_CHARGE,
  TX_AMT_L,
} from '../../../envs/vars';
import { BotTransaction } from '../utils/BotTransactions';
import { SolUtils } from '../../../solana/SolUtils';
import { useCreateWallet, useInitCompaign } from '../../../utils/networkCalls';
import { NotifyContext } from '../../../context/Notify';
import { MoonBotInfo } from '../components/MoonBotInfo';
import { BOT_DEFAULT_COUNT, BOT_DEFAULT_RATE } from '../utils/helpers';
import {
  MOONSHOT_BUMP_TX_AMOUNTS_TABS,
  VOLUME_TX_AMOUNT_TABS,
} from '../../../constants';
import { CopyString } from '../../../components/CopyString';

const CreateBump = () => {
  const [mode, setMode] = useState('BUMP');

  const [tokenDetails, setTokenDetails] = useState(null);
  const [solPrice, setSolPrice] = useState(0);
  const [notifyApi] = useContext(NotifyContext);
  const notifyRef = useRef(null);

  const { connection } = useConnection();
  const wallet = useWallet();
  const [formData, setFormData] = useState({
    amt: MOONSHOT_BUMP_TX_AMOUNTS_TABS[0].value,
    count: BOT_DEFAULT_COUNT,
    rate: BOT_DEFAULT_RATE,
    tip: JITO_TIP_1X,
  });
  const [botAccount, setBotAccount] = useState({
    pubKey: null,
    pvtKey: null,
    campaignId: null,
  });

  const navigate = useNavigate();

  const { mutate: createWallet } = useCreateWallet({
    onSuccess: data => {
      const { pubKey, campaignId, pvtKey } = data.data.data[0] || {};
      setBotAccount({ pubKey, campaignId, pvtKey });
    },
    onError: () => {
      message.error('Internal Server Error, Please contact team!!');
    },
  });

  const { mutate: initCompaign } = useInitCompaign({
    onSuccess: () => {
      let campaignId = notifyRef.current;
      notifyRef.current &&
        notifyApi &&
        notifyApi.open({
          key: notifyRef.current,
          placement: 'bottomRight',
          message: 'Request Confirmed',
          description: (
            <div>
              <p>
                Please go to Manage Section to check bot transaction details!!
              </p>
              <a href={`/moonshot/manage/${campaignId}`}>BOT DETAILS</a>
            </div>
          ),
          duration: 10,
        });
      notifyRef.current = null;
      setTimeout(() => {
        navigate(`/moonshot/manage/${campaignId}`);
      }, 10 * 1000);
    },
    onError: err => {
      message.error(`Unable to start bot, ${err?.message || ''}`);
      notifyRef.current &&
        notifyApi &&
        notifyApi.open({
          key: notifyRef.current,
          placement: 'bottomRight',
          message: 'Request Failed',
          description: (
            <div>
              <p>
                We are unable to process your request, Please contact team !!
              </p>
            </div>
          ),
          duration: 10,
        });
      notifyRef.current = null;
    },
  });

  // For solAmt
  const [solAmt, pfAmt] = useMemo(() => {
    const {
      count = BOT_DEFAULT_COUNT,
      rate = BOT_DEFAULT_RATE,
      amt,
    } = formData;

    const pdaFee = +BASE_PDA;
    const txnAmount = +formData.amt;
    const txnCount = +count || BOT_DEFAULT_COUNT;
    const txnRate = +rate || BOT_DEFAULT_RATE;
    const txnGasFee = +BOT_GAS_FEE;
    const botBaseFee = +BOT_BASE_FEE;
    const platformFee = +HYPERPAD_CHARGE;

    const solAmt = (
      2 * pdaFee +
      txnAmount +
      txnCount * (txnGasFee + 0.02 * txnAmount)
    ).toFixed(5);

    const pfAmt = (
      botBaseFee +
      txnCount * (platformFee * (1 + txnRate / 200))
    ).toFixed(5);

    // Return the amount as a number (not applying toFixed again)
    return [solAmt, pfAmt];
  }, [formData]);

  const createTxn = async () => {
    const pubKey = botAccount.pubKey;
    const campaignId = botAccount.campaignId;
    const txn = await BotTransaction.createTxn({
      connection,
      wallet,
      solAmt: solAmt,
      pfAmt: pfAmt,
      targetAddress: pubKey,
      mintAddress: tokenDetails?.mint,
    });

    const signedTxn = await SolUtils.getSignedTransaction(
      connection,
      txn,
      wallet,
      wallet.publicKey,
    );

    const base64Transaction = Buffer.from(signedTxn.serialize()).toString(
      'base64',
    );

    if (base64Transaction) {
      notifyRef.current = campaignId;
      notifyApi &&
        notifyApi.open({
          key: notifyRef.current,
          placement: 'bottomRight',
          message: 'Processing Request',
          description:
            'We are processing your request, Please wait while we confirm your request!!',
          duration: 60,
        });
      initCompaign({
        campaignId,
        createdBy: wallet.publicKey.toString(),
        txnPerMin: formData.rate || BOT_DEFAULT_RATE,
        jitoEnabled: false,
        jitoRatePerTxn: formData.tip * LAMPORTS_PER_SOL,
        maxTxnLimit: formData.count || BOT_DEFAULT_COUNT,
        exchangeType: 'MoonShot',
        tokenMintAddress: tokenDetails?.mint,
        symbol: tokenDetails?.metadata?.data?.symbol || '-',
        tradeAccountKey: pubKey,
        signedTxn: base64Transaction,
        perTxnAmt: formData.amt * LAMPORTS_PER_SOL,
        tokenName: tokenDetails?.metadata?.data?.name || '-',
        poolId: tokenDetails?.poolStatus?.pairAddress || '-',
      });
    }
  };

  return (
    <div className='flex gap-x-[2rem] flex-row md:flex-col md:gap-y-[2rem]'>
      <div className='flex flex-col w-[70%] md:w-[100%] p-6 gap-y-4 border bg-[var(--main-background-color)] border-[var(--main-border-color)] rounded-[5px]'>
        <h1>
          <span style={{ color: ' #9800ee' }}>Moonshot:</span>
          <span>&nbsp;Bump bot</span>
        </h1>
        <div
          style={{
            borderBottom: '2px solid gray',
            marginBottom: '1rem',
            marginTop: '0rem',
          }}
        ></div>
        <LoadToken
          setTokenDetails={setTokenDetails}
          setSolPrice={setSolPrice}
        />
        {tokenDetails ? <TokenInfo tokenDetails={tokenDetails} /> : null}
        <MoonBotForm
          solPrice={solPrice}
          formData={formData}
          setFormData={setFormData}
          mode={mode}
          setMode={setMode}
        />
        <div className='flex flex-row md:flex-col md:gap-y-[2rem] mt-8'>
          <HyperButton
            text={'1. Generate Bot Wallet'}
            className='w-fit mx-auto'
            onClick={() => createWallet({ platform: 'MoonShot' })}
            disabled={
              !tokenDetails ||
              !tokenDetails?.poolStatus?.pairAddress ||
              botAccount.campaignId
            }
          />
          {botAccount.campaignId && (
            <div className='flex flex-col ml-2 mr-2'>
              <p className='m-0'>
                <CopyString
                  data='Copy bot account private key'
                  dataToCopy={botAccount.pvtKey}
                  style={{ color: 'green' }}
                />
              </p>
            </div>
          )}
          <HyperButton
            className='w-fit mx-auto'
            text={`Pay and Start (~${(+solAmt + +pfAmt).toFixed(4)})`}
            onClick={() => createTxn()}
            disabled={
              !tokenDetails ||
              !tokenDetails?.poolStatus?.pairAddress ||
              !botAccount.campaignId
            }
            loading={false}
          />
        </div>
      </div>
      <div className='w-[30%] md:w-[100%] p-6 gap-y-4 border bg-[var(--main-background-color)] border-[var(--main-border-color)] rounded-[5px]'>
        <MoonBotInfo />
      </div>
    </div>
  );
};

export default CreateBump;
