import React from 'react';
import bumpiStyle from '../style/bumpi.module.less';

export const Tutorial = () => {
  return (
    <section className={bumpiStyle.tutorial}>
      <div className={bumpiStyle.videoContent}>
        <iframe
          width='100%'
          height='200'
          src='https://www.youtube.com/embed/hmpU4bGJdW0'
          title='How to bump a pumpfun token using bump bot on Solana.'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen
        ></iframe>
      </div>
      <div className={bumpiStyle.textContent}>
        <h2>Bumpi Bot</h2>
        <p>
          The Bumpi Bot is designed for bumping (micro-trading) the PumpFun
          tokens, making multiple small transactions to keep the token at the
          top of the PumpFun homepage.
        </p>
      </div>
    </section>
  );
};
