import React from 'react';
import Select from 'antd/es/select';
import Tooltip from 'antd/es/tooltip';
import { InfoCircleOutlined } from '@ant-design/icons';

export const LiquidityPools = ({
  pools = [],
  tokenDetails,
  setTokenDetails = () => {},
}) => {
  return (
    <div className='grid grid-cols-2 lg:grid-cols-1 gap-4 mt-[1rem]'>
      <div className='flex flex-col md:col-span-2 items-baseline gap-y-2 liquidityPoolSelect'>
        <b>
          Select liquidity pool:{' '}
          <Tooltip title='Select from the available liquidity pools'>
            <InfoCircleOutlined />
          </Tooltip>
        </b>
        <Select
          style={{ width: '100%' }}
          dropdownClassName='liquidityPoolSelect'
          onSelect={value => {
            setTokenDetails(prev => ({ ...prev, liquidityPool: value }));
          }}
          placeholder='Select liqudity pool'>
          {(pools || [])?.map(item => {
            return (
              <Select.Option
                value={
                  item.pairAddress
                }>{`${item.baseToken?.symbol}/${item.quoteToken?.symbol} ( ${item.pairAddress?.slice(0, 4)}....${item.pairAddress?.slice(-4)} )`}</Select.Option>
            );
          })}
        </Select>
      </div>
      <div className='flex flex-col'>
        <b>
          PoolId:{' '}
          <Tooltip title='Confirm below is the liquidity pool'>
            <InfoCircleOutlined />
          </Tooltip>
        </b>
        <p className='break-all mb-[0px] flex-1 content-center'>
          {tokenDetails?.liquidityPool}
        </p>
      </div>
    </div>
  );
};
